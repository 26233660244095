var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-6"
  }, [_vm.selectedTab === 'General Information' ? _c('div', {
    staticClass: "grid grid-cols-2 gap-4"
  }, [_c('div', {
    staticClass: "LEFT-CONTENT flex flex-col gap-4"
  }, [_c('ExpandableContent', {
    attrs: {
      "title": "Details",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4 pb-4 pt-2"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.layoutOptions,
      "label": "Layout Type",
      "optionLabel": "name",
      "default": _vm.payload.layoutType,
      "placeholder": "Select Layout Type"
    },
    model: {
      value: _vm.payload.layoutType,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "layoutType", $$v);
      },
      expression: "payload.layoutType"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "isLabelRequire": "",
      "label": "Slug (URL format)",
      "borderEnabled": true,
      "placeholder": "this-is-the-slug-format",
      "required": ""
    },
    model: {
      value: _vm.payload.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "slug", $$v);
      },
      expression: "payload.slug"
    }
  })], 1)])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Images",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "grid grid-cols-2 gap-4 py-2"
  }, [_c('div', {}, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium pl-3 mb-1 block"
  }, [_vm._v("Class Image")]), _vm.payload.url1 ? _c('div', [_c('div', {
    staticClass: "relative"
  }, [_c('img', {
    staticClass: "w-full aspect-square",
    attrs: {
      "src": _vm.payload.url1
    }
  }), _c('input', {
    ref: "classImage",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "id": "classImage",
      "accept": ".jpg,.jpeg,.png,.webp"
    },
    on: {
      "change": function change($event) {
        return _vm.handleFileSelect($event, _vm.payload, 'url1');
      }
    }
  })]), _c('div', {
    staticClass: "flex justify-center mt-3 gap-3"
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "size": "regular",
      "buttonText": "Change"
    },
    on: {
      "action": function action($event) {
        return _vm.changeImage('classImage');
      }
    }
  }), _c('Button', {
    attrs: {
      "type": "secondary",
      "size": "regular",
      "buttonText": "Delete"
    },
    on: {
      "action": function action() {
        _vm.payload.url1 = null;
      }
    }
  })], 1)]) : _c('div', [_c('div', {
    staticClass: "rounded-lg flex justify-center border-2 border-dashed border-neutral-50 h-56 relative"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Attach'), _c('p', {
    staticClass: "text-sm text-neutral pl-1"
  }, [_vm._v("Choose Image")])], 1), _c('input', {
    ref: "heroImage",
    staticClass: "w-full h-full cursor-pointer opacity-0 overflow-hidden absolute",
    attrs: {
      "type": "file",
      "id": "classImage",
      "accept": ".jpg,.jpeg,.png,.webp"
    },
    on: {
      "change": function change($event) {
        return _vm.handleFileSelect($event, _vm.payload, 'url1');
      }
    }
  })]), _c('p', {
    staticClass: "text-xs pt-1 text-neutral-500"
  }, [_vm._v("Ratio 16:9 (ideally 640x360 px)")])])]), _c('div', {}, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium pl-3 mb-1 block"
  }, [_vm._v("Hero Image")]), _vm.payload.url2 ? _c('div', [_c('div', {
    staticClass: "relative"
  }, [_c('img', {
    staticClass: "w-full aspect-square",
    attrs: {
      "src": _vm.payload.url2
    }
  }), _c('input', {
    ref: "heroImage",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "id": "heroImage",
      "accept": ".jpg,.jpeg,.png,.webp"
    },
    on: {
      "change": function change($event) {
        return _vm.handleFileSelect($event, _vm.payload, 'url2');
      }
    }
  })]), _c('div', {
    staticClass: "flex justify-center mt-3 gap-3"
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "size": "regular",
      "buttonText": "Change"
    },
    on: {
      "action": function action($event) {
        return _vm.changeImage('heroImage');
      }
    }
  }), _c('Button', {
    attrs: {
      "type": "secondary",
      "size": "regular",
      "buttonText": "Delete"
    },
    on: {
      "action": function action() {
        _vm.payload.url2 = null;
      }
    }
  })], 1)]) : _c('div', [_c('div', {
    staticClass: "rounded-lg flex justify-center border-2 border-dashed border-neutral-50 h-56 relative"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Attach'), _c('p', {
    staticClass: "text-sm text-neutral pl-1"
  }, [_vm._v("Choose Image")])], 1), _c('input', {
    ref: "heroImage",
    staticClass: "w-full h-full cursor-pointer opacity-0 overflow-hidden absolute",
    attrs: {
      "type": "file",
      "id": "heroImage",
      "accept": ".jpg,.jpeg,.png,.webp"
    },
    on: {
      "change": function change($event) {
        return _vm.handleFileSelect($event, _vm.payload, 'url2');
      }
    }
  })]), _c('p', {
    staticClass: "text-xs pt-1 text-neutral-500"
  }, [_vm._v("PNG max 2MB, 640x360 px (16:9)")])])])])])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Class Syllabus",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4 py-2"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Syllabus Document URL (optional)",
      "borderEnabled": true,
      "placeholder": "Input URL",
      "notes": "Make sure your URL can be accessed by others (link sharing: ON)"
    },
    on: {
      "input": function input(val) {
        _vm.$emit('handleSyllabusDocumentMutate', val);
      }
    },
    model: {
      value: _vm.syllabusMutate,
      callback: function callback($$v) {
        _vm.syllabusMutate = $$v;
      },
      expression: "syllabusMutate"
    }
  }), _c('div', {
    staticClass: "rounded-lg bg-neutral-100 w-full p-5 mb-4"
  }, [_c('h3', {
    staticClass: "text-xs text-neutral-500 font-semibold"
  }, [_vm._v("Software / Tools (optional, max 7)")]), _c('div', {
    staticClass: "flex flex-col gap-4 pt-3"
  }, [_vm._l(_vm.coreClassSoftwareTool, function (tools, toolIdx) {
    return _c('div', {
      key: "alumni" + toolIdx,
      staticClass: "flex gap-3 items-center w-full"
    }, [_c('div', {
      staticClass: "flex relative",
      staticStyle: {
        "width": "96%"
      }
    }, [_c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "label": "Software / Tools ".concat(toolIdx + 1),
        "borderEnabled": true,
        "placeholder": "Click upload image to insert logo",
        "disabledWhite": true,
        "notes": "Transparent PNG/SVG only, image size 120x60 px"
      },
      model: {
        value: _vm.coreClassSoftwareTool[toolIdx].softwareToolFileName,
        callback: function callback($$v) {
          _vm.$set(_vm.coreClassSoftwareTool[toolIdx], "softwareToolFileName", $$v);
        },
        expression: "coreClassSoftwareTool[toolIdx].softwareToolFileName"
      }
    }), _c('div', {
      staticClass: "absolute top-5 right-0 z-10"
    }, [_c('input', {
      ref: "software".concat(toolIdx),
      refInFor: true,
      staticClass: "hidden",
      attrs: {
        "type": "file",
        "name": "software".concat(toolIdx),
        "id": "software".concat(toolIdx),
        "accept": ".jpg, .png, .jpeg, .gif, .webp"
      },
      on: {
        "change": function change($event) {
          return _vm.handleSoftwareImage($event, toolIdx);
        }
      }
    }), _c('Button', {
      staticClass: "py-3",
      attrs: {
        "buttonText": "Upload Image",
        "size": "regular"
      },
      on: {
        "action": function action() {
          _vm.$refs["software".concat(toolIdx)][0].click();
        }
      }
    })], 1)], 1), _c('div', {
      staticStyle: {
        "width": "5%"
      }
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.coreClassSoftwareTool, toolIdx);
        }
      }
    })], 1)]);
  }), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Add Software/Tools",
      "disabled": _vm.isClassSoftwareToolIncludeEmptyContent || _vm.coreClassSoftwareTool.length >= 7,
      "size": "regular",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addItem(_vm.coreClassSoftwareTool, {
          softwareToolUrl: '',
          softwareToolFileName: ''
        });
      }
    }
  })], 1)], 2)])], 1)])], 2), _vm.programSelected === 'Prakerja' ? _c('ExpandableContent', {
    attrs: {
      "title": "Class Description",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_vm.programSelected === 'Prakerja' ? _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Class Value")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.classValue,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "classValue", $$v);
      },
      expression: "payload.classValue"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Precondition")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.prasyarat,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "prasyarat", $$v);
      },
      expression: "payload.prasyarat"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Sasaran Peserta")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.targetParticipant,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "targetParticipant", $$v);
      },
      expression: "payload.targetParticipant"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Aspek Kompetensi Pengetahuan")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.knowledgeCompetence,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "knowledgeCompetence", $$v);
      },
      expression: "payload.knowledgeCompetence"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Aspek Kompetensi Sikap")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.attitudeCompetence,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "attitudeCompetence", $$v);
      },
      expression: "payload.attitudeCompetence"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Metode Ajar/Latih dan Evaluasi Peserta")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.learningMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "learningMethod", $$v);
      },
      expression: "payload.learningMethod"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Notes")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.note,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "note", $$v);
      },
      expression: "payload.note"
    }
  })], 1), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Tujuan Pelatihan")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.purpose,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "purpose", $$v);
      },
      expression: "payload.purpose"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Aktivitas Pelatihan")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.activity,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "activity", $$v);
      },
      expression: "payload.activity"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Aspek Kompetensi Keterampilan")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.skillCompetence,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "skillCompetence", $$v);
      },
      expression: "payload.skillCompetence"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Kapasitas Jenis / Kelas Pelatihan")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.capacity,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "capacity", $$v);
      },
      expression: "payload.capacity"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Jenis Klarifikasi Sertifikat")]), _c('quill-editor', {
    ref: "descQuillEditor",
    attrs: {
      "options": _vm.quillOptions
    },
    model: {
      value: _vm.payload.certificateClarification,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "certificateClarification", $$v);
      },
      expression: "payload.certificateClarification"
    }
  })], 1)]) : _vm._e()])], 2) : _vm._e()], 1), _c('div', {
    staticClass: "RIGHT-CONTENT flex flex-col gap-4"
  }, [_c('ExpandableContent', {
    attrs: {
      "title": "Price",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "py-4"
  }, [_c('div', {
    staticClass: "mb-6 flex gap-6"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Listing Price")]), _c('CurrencyInput', {
    model: {
      value: _vm.payload.price,
      callback: function callback($$v) {
        _vm.$set(_vm.payload, "price", $$v);
      },
      expression: "payload.price"
    }
  })], 1)]), _c('div', {
    staticClass: "w-1/2 mx-auto"
  }, [_c('table', {
    staticClass: "table-fixed border-collapse w-full"
  }, [_c('thead', [_c('tr', [_c('td', {
    staticClass: "text-xs font-bold bg-yellow-secondary px-2 py-1"
  }, [_vm._v("Condition")]), _c('td', {
    staticClass: "text-xs font-bold bg-yellow-secondary px-2 py-1"
  }, [_vm._v("Result / Display")])])]), _c('tbody', [_c('tr', {
    staticClass: "border-b border-grey-field"
  }, [_c('td', {
    staticClass: "text-xs px-2 py-2"
  }, [_vm._v("Listing Price > 0")]), _c('td', {
    staticClass: "text-xs px-2 py-2"
  }, [_vm._v("Only for Rp {price}")])]), _c('tr', {
    staticClass: "border-b border-grey-field"
  }, [_c('td', {
    staticClass: "text-xs px-2 py-2"
  }, [_vm._v("Listing Price = 0")]), _c('td', {
    staticClass: "text-xs px-2 py-2"
  }, [_vm._v("\"Join now for free!\" (copy can be adjusted accordingly)")])]), _c('tr', {
    staticClass: "border-b border-grey-field"
  }, [_c('td', {
    staticClass: "text-xs px-2 py-1"
  }, [_vm._v("Listing Price empty")]), _c('td', {
    staticClass: "text-xs px-2 py-2"
  }, [_vm._v("Get a special price!")])])])])])]), _c('div', {
    staticClass: "mb-6"
  }, [_vm._l(_vm.scheduledPrices, function (item, idx) {
    var _item$scheduleType, _item$scheduleType2, _item$scheduleType3;

    return _c('div', {
      key: idx,
      staticClass: "bg-neutral-100 p-4 rounded-lg mb-3"
    }, [_c('div', {
      staticClass: "core__form-row"
    }, [_c('div', {
      staticClass: "core__form-row-item"
    }, [_c('div', {
      staticClass: "flex items-start justify-between gap-1"
    }, [_c('span', {
      staticStyle: {
        "font-size": "24px",
        "font-weight": "bold"
      }
    }, [_vm._v("Promo Price")]), _c('div', {
      staticClass: "core__form-row-item flex justify-end"
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteObjectItem(_vm.scheduledPrices, idx);
        }
      }
    })], 1)])])]), _c('div', {
      staticClass: "core__form-row mt-6"
    }, [_c('div', {
      staticClass: "my-4"
    }, [_c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "label": "Promo Label",
        "borderEnabled": true,
        "placeholder": "Promo Label will appear on Class Detail page"
      },
      model: {
        value: item.promoLabel,
        callback: function callback($$v) {
          _vm.$set(item, "promoLabel", $$v);
        },
        expression: "item.promoLabel"
      }
    })], 1), _c('div', {
      staticClass: "my-4"
    }, [_c('span', {
      staticClass: "font-medium text-sm"
    }, [_vm._v("Promo Price")]), _c('CurrencyInput', {
      model: {
        value: item.price,
        callback: function callback($$v) {
          _vm.$set(item, "price", $$v);
        },
        expression: "item.price"
      }
    })], 1), _c('div', {
      staticClass: "my-4"
    }, [_c('span', {
      staticClass: "font-medium text-sm"
    }, [_vm._v("Promo Type")]), _c('Dropdown', {
      attrs: {
        "options": _vm.listPromoType,
        "default": item.scheduleType,
        "optionLabel": "name",
        "placeholder": "Promo Type"
      },
      model: {
        value: item.scheduleType,
        callback: function callback($$v) {
          _vm.$set(item, "scheduleType", $$v);
        },
        expression: "item.scheduleType"
      }
    })], 1), ((_item$scheduleType = item.scheduleType) === null || _item$scheduleType === void 0 ? void 0 : _item$scheduleType.code) === 'SCHEDULEDPROMO' ? _c('div', {
      staticClass: "core__form-row-item flex justify-between gap-2 date"
    }, [_c('div', {
      staticClass: "flex-1"
    }, [_c('div', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Start Date"), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.withTime,
        expression: "!item.withTime"
      }],
      staticClass: "text-red"
    }, [_vm._v(" *")])]), ((_item$scheduleType2 = item.scheduleType) === null || _item$scheduleType2 === void 0 ? void 0 : _item$scheduleType2.code) === 'SCHEDULEDPROMO' ? _c('div', {
      staticClass: "relative"
    }, [_c('span', {
      staticClass: "inline-block absolute top-1/2 right-4 transform -translate-y-1/2"
    }, [_c('Calendar', {
      attrs: {
        "color": "neutral-500"
      }
    })], 1), _c('datetime', {
      attrs: {
        "type": "datetime",
        "zone": "Asia/Jakarta",
        "format": "dd-MM-yyyy HH:mm",
        "placeholder": "Select Date",
        "min-datetime": _vm.minimalstartDate(idx)
      },
      model: {
        value: item.startDate,
        callback: function callback($$v) {
          _vm.$set(item, "startDate", $$v);
        },
        expression: "item.startDate"
      }
    })], 1) : _c('div', [_c('span', {
      staticClass: "inline-block absolute top-1/2 right-4 transform -translate-y-1/2"
    }, [_c('Calendar', {
      attrs: {
        "color": "neutral-500"
      }
    })], 1), _c('datetime', {
      attrs: {
        "type": "datetime",
        "zone": "Asia/Jakarta",
        "format": "dd-MM-yyyy HH:mm",
        "placeholder": "Select Date"
      },
      model: {
        value: item.startDate,
        callback: function callback($$v) {
          _vm.$set(item, "startDate", $$v);
        },
        expression: "item.startDate"
      }
    })], 1)]), _c('div', {
      staticClass: "flex-1"
    }, [_c('div', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("End Date"), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.withTime,
        expression: "!item.withTime"
      }],
      staticClass: "text-red"
    }, [_vm._v(" *")])]), _c('div', {
      staticClass: "relative"
    }, [_c('span', {
      staticClass: "inline-block absolute top-1/2 right-4 transform -translate-y-1/2"
    }, [_c('Calendar', {
      attrs: {
        "color": "neutral-500"
      }
    })], 1), _c('datetime', {
      attrs: {
        "type": "datetime",
        "zone": "Asia/Jakarta",
        "format": "dd-MM-yyyy HH:mm",
        "placeholder": "Select Date",
        "min-datetime": item.startDate
      },
      model: {
        value: item.endDate,
        callback: function callback($$v) {
          _vm.$set(item, "endDate", $$v);
        },
        expression: "item.endDate"
      }
    })], 1)])]) : _vm._e(), ((_item$scheduleType3 = item.scheduleType) === null || _item$scheduleType3 === void 0 ? void 0 : _item$scheduleType3.code) === 'CLASSSCHEDULESDDAY' ? _c('div', {
      staticClass: "my-4"
    }, [_c('span', {
      staticClass: "font-medium text-sm"
    }, [_vm._v("Promo End Date")]), _c('Dropdown', {
      attrs: {
        "options": _vm.mappingDayName,
        "default": item.dayBeforeSchedule,
        "optionLabel": "name",
        "placeholder": "Promo Days"
      },
      model: {
        value: item.dayBeforeSchedule,
        callback: function callback($$v) {
          _vm.$set(item, "dayBeforeSchedule", $$v);
        },
        expression: "item.dayBeforeSchedule"
      }
    }), _c('p', {
      staticClass: "text-sm text-neutral-400 pl-3 pt-2"
    }, [_vm._v("Promo will be applied until (n) Day before the Class Schedule starts")])], 1) : _vm._e()])]);
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.scheduledPrices.length === 0,
      expression: "scheduledPrices.length === 0"
    }],
    staticClass: "flex mt-3"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add New Promo Price",
      "size": "compact",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreItem(_vm.scheduledPrices, 'prices');
      }
    }
  })], 1)], 2), _c('div', {
    staticClass: "rounded-lg bg-neutral-100 w-full p-5"
  }, [_c('h3', {
    staticClass: "text-xs text-neutral-500 font-semibold"
  }, [_vm._v("Payment Methods")]), _c('p', {
    staticClass: "text-xs text-neutral-400 py-2"
  }, [_vm._v("If empty, payment method information will be hidden")]), _c('div', {
    staticClass: "flex gap-4 pt-2"
  }, [_c('div', {
    staticClass: "grid grid-cols-2 gap-x-28 gap-y-5"
  }, _vm._l(_vm.paymentMethods, function (content, contentIndex) {
    return _c('div', {
      key: 'paymentMethods' + contentIndex
    }, [_c('div', {
      staticClass: "leading-0"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.paymentSelected,
        expression: "paymentSelected"
      }],
      staticClass: "styled-checkbox",
      attrs: {
        "type": "checkbox",
        "id": content.name
      },
      domProps: {
        "value": content.code,
        "checked": Array.isArray(_vm.paymentSelected) ? _vm._i(_vm.paymentSelected, content.code) > -1 : _vm.paymentSelected
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.paymentSelected,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = content.code,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.paymentSelected = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.paymentSelected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.paymentSelected = $$c;
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": content.name
      }
    }, [_vm._v(_vm._s(content.name))])])]);
  }), 0), _c('div')])])])])], 2), _c('ExpandableContent', {
    attrs: {
      "title": "Class Testimony",
      "expandContentIsOpen": ""
    }
  }, [_c('template', {
    slot: "dropdown-content"
  }, [_c('div', {
    staticClass: "py-2"
  }, [_c('div', {
    staticClass: "rounded-lg bg-neutral-100 w-full p-5 flex flex-col gap-4"
  }, [_vm._l(_vm.historyAlumniClasses, function (alumni, alumniIdx) {
    return _c('div', {
      key: "alumni" + alumniIdx,
      staticClass: "flex gap-3 items-center"
    }, [_c('Dropdown', {
      staticStyle: {
        "width": "96%"
      },
      attrs: {
        "options": _vm.testimonyList,
        "label": "Testimony ".concat(alumniIdx + 1),
        "optionLabel": "userName",
        "default": _vm.historyAlumniClasses[alumniIdx],
        "placeholder": "Select testimony"
      },
      model: {
        value: _vm.historyAlumniClasses[alumniIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.historyAlumniClasses, alumniIdx, $$v);
        },
        expression: "historyAlumniClasses[alumniIdx]"
      }
    }), _c('div', {
      staticStyle: {
        "width": "4%"
      },
      attrs: {
        "width": "flex items-center"
      }
    }, [_c('Button', {
      staticClass: "mt-4",
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteItem(_vm.historyAlumniClasses, alumniIdx);
        }
      }
    })], 1)], 1);
  }), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Add Class Testimony",
      "disabled": _vm.isClassTestimonyIncludeEmptyContent,
      "size": "regular",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addItem(_vm.historyAlumniClasses, null);
      }
    }
  })], 1)], 2)])])], 2)], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }