<template>
  <div class="p-6">
    <div class="grid grid-cols-2 gap-4" v-if="selectedTab === 'General Information'">
      <div class="LEFT-CONTENT flex flex-col gap-4">
        <ExpandableContent title="Details" expandContentIsOpen>
          <template slot="dropdown-content">
            <div class="flex flex-col gap-4 pb-4 pt-2">
              <Dropdown :options="layoutOptions" label="Layout Type" optionLabel="name" v-model="payload.layoutType" :default="payload.layoutType" placeholder="Select Layout Type" />
              <TextField type="text" isLabelRequire label="Slug (URL format)" :borderEnabled="true" placeholder="this-is-the-slug-format" v-model="payload.slug" required />
            </div>
          </template>
        </ExpandableContent>
        <ExpandableContent title="Images" expandContentIsOpen>
          <template slot="dropdown-content">
            <div class="grid grid-cols-2 gap-4 py-2">
              <div class="">
                <label class="text-neutral-500 text-xs font-medium pl-3 mb-1 block">Class Image</label>
                <div v-if="payload.url1">
                  <div class="relative">
                    <img :src="payload.url1" class="w-full aspect-square" />
                    <input type="file" id="classImage" class="hidden" @change="handleFileSelect($event, payload, 'url1')" ref="classImage" accept=".jpg,.jpeg,.png,.webp" />
                  </div>
                  <div class="flex justify-center mt-3 gap-3">
                    <Button type="primary" size="regular" buttonText="Change" @action="changeImage('classImage')" />
                    <Button
                      type="secondary"
                      size="regular"
                      buttonText="Delete"
                      @action="
                        () => {
                          payload.url1 = null
                        }
                      "
                    />
                  </div>
                </div>
                <div v-else>
                  <div class="rounded-lg flex justify-center border-2 border-dashed border-neutral-50 h-56 relative">
                    <div class="flex items-center">
                      <Attach />
                      <p class="text-sm text-neutral pl-1">Choose Image</p>
                    </div>
                    <input
                      type="file"
                      id="classImage"
                      class="w-full h-full cursor-pointer opacity-0 overflow-hidden absolute"
                      @change="handleFileSelect($event, payload, 'url1')"
                      ref="heroImage"
                      accept=".jpg,.jpeg,.png,.webp"
                    />
                  </div>
                  <p class="text-xs pt-1 text-neutral-500">Ratio 16:9 (ideally 640x360 px)</p>
                </div>
              </div>
              <div class="">
                <label class="text-neutral-500 text-xs font-medium pl-3 mb-1 block">Hero Image</label>
                <div v-if="payload.url2">
                  <div class="relative">
                    <img :src="payload.url2" class="w-full aspect-square" />
                    <input type="file" id="heroImage" class="hidden" @change="handleFileSelect($event, payload, 'url2')" ref="heroImage" accept=".jpg,.jpeg,.png,.webp" />
                  </div>
                  <div class="flex justify-center mt-3 gap-3">
                    <Button type="primary" size="regular" buttonText="Change" @action="changeImage('heroImage')" />
                    <Button
                      type="secondary"
                      size="regular"
                      buttonText="Delete"
                      @action="
                        () => {
                          payload.url2 = null
                        }
                      "
                    />
                  </div>
                </div>
                <div v-else>
                  <div class="rounded-lg flex justify-center border-2 border-dashed border-neutral-50 h-56 relative">
                    <div class="flex items-center">
                      <Attach />
                      <p class="text-sm text-neutral pl-1">Choose Image</p>
                    </div>
                    <input
                      type="file"
                      id="heroImage"
                      class="w-full h-full cursor-pointer opacity-0 overflow-hidden absolute"
                      @change="handleFileSelect($event, payload, 'url2')"
                      ref="heroImage"
                      accept=".jpg,.jpeg,.png,.webp"
                    />
                  </div>
                  <p class="text-xs pt-1 text-neutral-500">PNG max 2MB, 640x360 px (16:9)</p>
                </div>
              </div>
            </div>
          </template>
        </ExpandableContent>
        <ExpandableContent title="Class Syllabus" expandContentIsOpen>
          <template slot="dropdown-content">
            <div class="flex flex-col gap-4 py-2">
              <TextField
                type="text"
                label="Syllabus Document URL (optional)"
                :borderEnabled="true"
                placeholder="Input URL"
                notes="Make sure your URL can be accessed by others (link sharing: ON)"
                v-model="syllabusMutate"
                @input="
                  (val) => {
                    $emit('handleSyllabusDocumentMutate', val)
                  }
                "
              />
              <div class="rounded-lg bg-neutral-100 w-full p-5 mb-4">
                <h3 class="text-xs text-neutral-500 font-semibold">Software / Tools (optional, max 7)</h3>
                <div class="flex flex-col gap-4 pt-3">
                  <div class="flex gap-3 items-center w-full" v-for="(tools, toolIdx) in coreClassSoftwareTool" :key="`alumni` + toolIdx">
                    <div class="flex relative" style="width: 96%">
                      <TextField
                        class="w-full"
                        type="text"
                        :label="`Software / Tools ${toolIdx + 1}`"
                        :borderEnabled="true"
                        placeholder="Click upload image to insert logo"
                        :disabledWhite="true"
                        notes="Transparent PNG/SVG only, image size 120x60 px"
                        v-model="coreClassSoftwareTool[toolIdx].softwareToolFileName"
                      />
                      <div class="absolute top-5 right-0 z-10">
                        <input
                          type="file"
                          :name="`software${toolIdx}`"
                          :id="`software${toolIdx}`"
                          accept=".jpg, .png, .jpeg, .gif, .webp"
                          :ref="`software${toolIdx}`"
                          @change="handleSoftwareImage($event, toolIdx)"
                          class="hidden"
                        />
                        <Button
                          buttonText="Upload Image"
                          class="py-3"
                          size="regular"
                          @action="
                            () => {
                              $refs[`software${toolIdx}`][0].click()
                            }
                          "
                        />
                      </div>
                    </div>
                    <div style="width: 5%">
                      <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteItem(coreClassSoftwareTool, toolIdx)" />
                    </div>
                  </div>
                  <div>
                    <Button
                      buttonText="Add Software/Tools"
                      :disabled="isClassSoftwareToolIncludeEmptyContent || coreClassSoftwareTool.length >= 7"
                      size="regular"
                      type="tertiary"
                      @action="addItem(coreClassSoftwareTool, { softwareToolUrl: '', softwareToolFileName: '' })"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ExpandableContent>
        <ExpandableContent title="Class Description" expandContentIsOpen v-if="programSelected === 'Prakerja'">
          <template slot="dropdown-content">
            <div class="flex flex-col gap-6" v-if="programSelected === 'Prakerja'">
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Class Value</label>
                <quill-editor ref="descQuillEditor" v-model="payload.classValue" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Precondition</label>
                <quill-editor ref="descQuillEditor" v-model="payload.prasyarat" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Sasaran Peserta</label>
                <quill-editor ref="descQuillEditor" v-model="payload.targetParticipant" :options="quillOptions" />
              </div>
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aspek Kompetensi Pengetahuan</label>
                <quill-editor ref="descQuillEditor" v-model="payload.knowledgeCompetence" :options="quillOptions" />
              </div>
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aspek Kompetensi Sikap</label>
                <quill-editor ref="descQuillEditor" v-model="payload.attitudeCompetence" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Metode Ajar/Latih dan Evaluasi Peserta</label>
                <quill-editor ref="descQuillEditor" v-model="payload.learningMethod" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Notes</label>
                <quill-editor ref="descQuillEditor" v-model="payload.note" :options="quillOptions" />
              </div>
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Tujuan Pelatihan</label>
                <quill-editor ref="descQuillEditor" v-model="payload.purpose" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aktivitas Pelatihan</label>
                <quill-editor ref="descQuillEditor" v-model="payload.activity" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Aspek Kompetensi Keterampilan</label>
                <quill-editor ref="descQuillEditor" v-model="payload.skillCompetence" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Kapasitas Jenis / Kelas Pelatihan</label>
                <quill-editor ref="descQuillEditor" v-model="payload.capacity" :options="quillOptions" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Jenis Klarifikasi Sertifikat</label>
                <quill-editor ref="descQuillEditor" v-model="payload.certificateClarification" :options="quillOptions" />
              </div>
            </div>
          </template>
        </ExpandableContent>
      </div>
      <div class="RIGHT-CONTENT flex flex-col gap-4">
        <ExpandableContent title="Price" expandContentIsOpen>
          <template slot="dropdown-content">
            <div class="py-4">
              <div class="mb-6 flex gap-6">
                <div class="w-1/2">
                  <div class="mb-6">
                    <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Listing Price</label>
                    <CurrencyInput v-model="payload.price" />
                  </div>
                </div>
                <div class="w-1/2 mx-auto">
                  <table class="table-fixed border-collapse w-full">
                    <thead>
                      <tr>
                        <td class="text-xs font-bold bg-yellow-secondary px-2 py-1">Condition</td>
                        <td class="text-xs font-bold bg-yellow-secondary px-2 py-1">Result / Display</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="border-b border-grey-field">
                        <td class="text-xs px-2 py-2">Listing Price > 0</td>
                        <td class="text-xs px-2 py-2">Only for Rp {price}</td>
                      </tr>
                      <tr class="border-b border-grey-field">
                        <td class="text-xs px-2 py-2">Listing Price = 0</td>
                        <td class="text-xs px-2 py-2">"Join now for free!" (copy can be adjusted accordingly)</td>
                      </tr>
                      <tr class="border-b border-grey-field">
                        <td class="text-xs px-2 py-1">Listing Price empty</td>
                        <td class="text-xs px-2 py-2">Get a special price!</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mb-6">
                <div class="bg-neutral-100 p-4 rounded-lg mb-3" v-for="(item, idx) in scheduledPrices" :key="idx">
                  <div class="core__form-row">
                    <div class="core__form-row-item">
                      <div class="flex items-start justify-between gap-1">
                        <span style="font-size: 24px; font-weight: bold">Promo Price</span>
                        <div class="core__form-row-item flex justify-end">
                          <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteObjectItem(scheduledPrices, idx)" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="core__form-row mt-6">
                    <div class="my-4">
                      <TextField class="w-full" type="text" label="Promo Label" :borderEnabled="true" placeholder="Promo Label will appear on Class Detail page" v-model="item.promoLabel" />
                    </div>
                    <div class="my-4">
                      <span class="font-medium text-sm">Promo Price</span>
                      <CurrencyInput v-model="item.price" />
                    </div>
                    <div class="my-4">
                      <span class="font-medium text-sm">Promo Type</span>
                      <Dropdown :options="listPromoType" :default="item.scheduleType" optionLabel="name" v-model="item.scheduleType" placeholder="Promo Type" />
                    </div>
                    <div class="core__form-row-item flex justify-between gap-2 date" v-if="item.scheduleType?.code === 'SCHEDULEDPROMO'">
                      <div class="flex-1">
                        <div class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Start Date<span v-show="!item.withTime" class="text-red"> *</span></div>
                        <div class="relative" v-if="item.scheduleType?.code === 'SCHEDULEDPROMO'">
                          <span class="inline-block absolute top-1/2 right-4 transform -translate-y-1/2">
                            <Calendar color="neutral-500" />
                          </span>
                          <datetime type="datetime" v-model="item.startDate" zone="Asia/Jakarta" format="dd-MM-yyyy HH:mm" placeholder="Select Date" :min-datetime="minimalstartDate(idx)"></datetime>
                        </div>
                        <div v-else>
                          <span class="inline-block absolute top-1/2 right-4 transform -translate-y-1/2">
                            <Calendar color="neutral-500" />
                          </span>
                          <datetime type="datetime" v-model="item.startDate" zone="Asia/Jakarta" format="dd-MM-yyyy HH:mm" placeholder="Select Date"></datetime>
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">End Date<span v-show="!item.withTime" class="text-red"> *</span></div>
                        <div class="relative">
                          <span class="inline-block absolute top-1/2 right-4 transform -translate-y-1/2">
                            <Calendar color="neutral-500" />
                          </span>
                          <datetime type="datetime" v-model="item.endDate" zone="Asia/Jakarta" format="dd-MM-yyyy HH:mm" placeholder="Select Date" :min-datetime="item.startDate"></datetime>
                        </div>
                      </div>
                    </div>
                    <div class="my-4" v-if="item.scheduleType?.code === 'CLASSSCHEDULESDDAY'">
                      <span class="font-medium text-sm">Promo End Date</span>
                      <Dropdown :options="mappingDayName" :default="item.dayBeforeSchedule" optionLabel="name" v-model="item.dayBeforeSchedule" placeholder="Promo Days" />
                      <p class="text-sm text-neutral-400 pl-3 pt-2">Promo will be applied until (n) Day before the Class Schedule starts</p>
                    </div>
                  </div>
                </div>
                <div v-show="scheduledPrices.length === 0" class="flex mt-3">
                  <Button buttonText="Add New Promo Price" size="compact" type="tertiary" @action="addMoreItem(scheduledPrices, 'prices')" />
                </div>
              </div>
              <div class="rounded-lg bg-neutral-100 w-full p-5">
                <h3 class="text-xs text-neutral-500 font-semibold">Payment Methods</h3>
                <p class="text-xs text-neutral-400 py-2">If empty, payment method information will be hidden</p>
                <div class="flex gap-4 pt-2">
                  <div class="grid grid-cols-2 gap-x-28 gap-y-5">
                    <div class="" v-for="(content, contentIndex) in paymentMethods" :key="'paymentMethods' + contentIndex">
                      <div class="leading-0">
                        <input type="checkbox" :id="content.name" :value="content.code" v-model="paymentSelected" class="styled-checkbox" />
                        <label :for="content.name">{{ content.name }}</label>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </template>
        </ExpandableContent>
        <ExpandableContent title="Class Testimony" expandContentIsOpen>
          <template slot="dropdown-content">
            <div class="py-2">
              <div class="rounded-lg bg-neutral-100 w-full p-5 flex flex-col gap-4">
                <div class="flex gap-3 items-center" v-for="(alumni, alumniIdx) in historyAlumniClasses" :key="`alumni` + alumniIdx">
                  <Dropdown
                    style="width: 96%"
                    :options="testimonyList"
                    :label="`Testimony ${alumniIdx + 1}`"
                    optionLabel="userName"
                    v-model="historyAlumniClasses[alumniIdx]"
                    :default="historyAlumniClasses[alumniIdx]"
                    placeholder="Select testimony"
                  />
                  <div style="width: 4%" width="flex items-center">
                    <Button
                      class="mt-4"
                      type="secondary"
                      size="icon"
                      :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                      @action="deleteItem(historyAlumniClasses, alumniIdx)"
                    />
                  </div>
                </div>
                <div>
                  <Button buttonText="Add Class Testimony" :disabled="isClassTestimonyIncludeEmptyContent" size="regular" type="tertiary" @action="addItem(historyAlumniClasses, null)" />
                </div>
              </div>
            </div>
          </template>
        </ExpandableContent>
      </div>
    </div>
  </div>
</template>

<script>
import { showVueToast } from '@/utils'
import moment from 'moment'
import ImageUploadMixin from '@/mixins/image-upload'
import { mapActions, mapGetters } from 'vuex'
export default {
  mixins: [ImageUploadMixin],
  props: {
    payload: {
      type: Object,
      default: () => {}
    },
    programSelected: {
      type: String,
      default: ''
    },
    scheduledPrices: {
      type: Array,
      default: () => []
    },
    historyAlumniClasses: {
      type: Array,
      default: () => []
    },
    testimonyList: {
      type: Array,
      default: () => []
    },
    coreClassPaymentMethod: {
      type: Array,
      default: () => []
    },
    syllabusDocument: {
      type: String,
      default: ''
    },
    coreClassSoftwareTool: {
      type: Array,
      default: () => []
    },
    bilingualContent: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    Chevron: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Chevron'),
    Attach: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Attach'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    TextArea: () => import(/* webpackChunkName: "TextArea" */ '@/components/Form/TextArea'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    CurrencyInput: () => import(/* webpackChunkName: "CurrencyInput" */ '@/components/Form/CurrencyInput'),
    ExpandableContent: () => import(/* webpackChunkName: "ExpandableContent" */ '@/components/Expandable/ExpandableContent'),
    Calendar: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Calendar')
  },
  data() {
    return {
      detailVisible: false,
      priceVisible: false,
      heroVisible: false,
      layoutOptions: [
        {
          name: 'Event',
          value: 'EVENT_LAYOUT'
        },
        {
          name: 'Class',
          value: 'CLASS_LAYOUT'
        },
        {
          name: 'Class + Schedule',
          value: 'CLASS_SCHEDULE_LAYOUT'
        },
        {
          name: 'Collaboration',
          value: 'COLLABORATION'
        }
      ],
      quillOptions: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['link'], ['clean']]
        }
      },
      maxFileDocument: 2000000,
      listPromoType: [
        {
          name: 'Lifetime',
          code: 'LIFETIME'
        },
        {
          name: 'Scheduled Promo',
          code: 'SCHEDULEDPROMO'
        }
      ],
      paymentSelected: [],
      locationOptions: ['Online', 'Offline'],
      syllabusMutate: '',
      tabOptions: ['General Information', 'English', 'Bahasa Indonesia'],
      selectedTab: 'General Information'
    }
  },
  mounted() {
    this.GET_PAYMENT_METHODS()
    this.mappingDataClassPaymentMethod()
    this.syllabusMutate = JSON.parse(JSON.stringify(this.syllabusDocument || ''))
  },
  watch: {
    paymentSelectedLength() {
      const paymentMethodData = this.paymentSelected.map((code) => {
        const result = this.paymentMethods.filter((payList) => payList.code === code)
        return {
          code: result[0].code
        }
      })
      this.$emit('handlePaymentMethodSelected', paymentMethodData)
    }
  },
  computed: {
    // ...mapGetters('kelas', ['paymentMethods']),
    paymentMethods() {
      return [
        {
          code: 'Cash',
          name: 'Cash',
          id: 1
        },
        {
          code: 'Scholarship',
          name: 'Scholarship',
          id: 3
        },
        {
          code: 'Pay As You Go',
          name: 'Pay As You Go',
          id: 34
        },
        {
          code: 'Installment',
          name: 'Installment',
          id: 4
        },
        {
          code: 'ISA',
          name: 'ISA',
          id: 2
        },
        {
          code: 'Credit Card',
          name: 'Credit Card',
          id: 5
        }
      ]
    },
    languangeIndex() {
      if (this.selectedTab === 'Bahasa Indonesia') return 1
      return 0
    },
    isClassStagesIncludeEmptyContent() {
      const classStagesContent = this.bilingualContent[this.languangeIndex].coreClassStage.filter((stage) => stage?.title && stage?.description)
      const result = classStagesContent.length === this.bilingualContent[this.languangeIndex].coreClassStage.length ? false : true
      return result
    },
    isClassOutcomeIncludeEmptyContent() {
      const classOutcomeContent = this.bilingualContent[this.languangeIndex].coreClassOutCome[0]?.outComesName?.filter((item) => item)
      const result = classOutcomeContent.length === this.bilingualContent[this.languangeIndex].coreClassOutCome[0]?.outComesName?.length ? false : true
      return result
    },
    isClassBenefitsIncluedEmptyContent() {
      const classBenefits = this.bilingualContent[this.languangeIndex].coreClassOutCome[0]?.benefitItems?.filter((item) => item)
      const result = classBenefits.length === this.bilingualContent[this.languangeIndex].coreClassOutCome[0]?.benefitItems?.length ? false : true
      return result
    },
    isClassFaqIncludeEmptyContent() {
      const classFaqs = this.bilingualContent[this.languangeIndex].coreClassFaqs.filter((stage) => stage?.answer && stage?.question)
      const result = classFaqs.length === this.bilingualContent[this.languangeIndex].coreClassFaqs.length ? false : true
      return result
    },
    isClassTestimonyIncludeEmptyContent() {
      const testimony = this.historyAlumniClasses.filter((testimony) => testimony)
      const result = testimony.length === this.historyAlumniClasses.length ? false : true
      return result
    },
    isClassSoftwareToolIncludeEmptyContent() {
      const tool = this.coreClassSoftwareTool.filter((url) => url.softwareToolUrl)
      const result = tool.length === this.coreClassSoftwareTool.length ? false : true
      return result
    },
    mappingDayName() {
      return this.listPromoDays?.map((item) => {
        if (item === 1) {
          return {
            name: item + ' day before',
            value: item
          }
        } else {
          return {
            name: item + ' days before',
            value: item
          }
        }
      })
    },
    paymentSelectedLength() {
      return this.paymentSelected.length
    }
  },
  methods: {
    ...mapActions('kelas', ['GET_PAYMENT_METHODS']),
    // choose bilingual tab
    selectTab(tab) {
      this.selectedTab = tab
    },
    readFileNameFromAWSLink(link) {
      if (link) return link.substring(66, link.length)
      return ''
    },
    mappingDataClassPaymentMethod() {
      this.paymentSelected = this.coreClassPaymentMethod.map((value) => value.code)
    },
    addItem(objContent, item) {
      objContent.push(item)
    },
    deleteItem(objContent, idx) {
      objContent.splice(idx, 1)
    },
    addMoreSyllabusClasses() {
      const obj = this.bilingualContent[this.languangeIndex].sylabusClasses
      let lastObj = {
        seqNo: 0
      }
      if (obj.length > 0) {
        lastObj = obj[obj.length - 1]
      }
      const item = {
        courseKit: '',
        description: '',
        name: '',
        seqNo: lastObj.seqNo + 1,
        title: ''
      }
      obj.push(item)
    },
    handleSoftwareImage(evt, idx) {
      const imageData = new FormData()
      const fileName = evt.target.files[0].name.split('.')[0]
      imageData.append('file', evt.target.files[0])
      imageData.append('name', fileName)
      this.showLoading()
      this.uploadImageAsset({
        payloads: imageData
      })
        .then((res) => {
          let link = res.data.url
          this.coreClassSoftwareTool[idx].softwareToolFileName = this.readFileNameFromAWSLink(link)
          this.coreClassSoftwareTool[idx].softwareToolUrl = link
          this.hideLoading()
        })
        .catch(() => {
          showVueToast('Upload image failed !', 'error', 2000)
          this.hideLoading()
        })
    },
    handleFileSelect(evt, item, field) {
      this.uploadImage(evt, item, field)
    },
    changeImage(item) {
      this.$refs[item].click()
    },
    minimalstartDate(index) {
      if (index === 0) {
        return moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      } else {
        return moment(this.scheduledPrices[index - 1].endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    addMoreItem(objectContent, type) {
      this.$emit('addMoreItem', objectContent, type)
    },
    deleteObjectItem(objectContent, idx) {
      this.$emit('deleteItem', objectContent, idx)
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 12px;
  line-height: normal;
  font-size: 14px;
  resize: none;
  height: 120px;
  overflow: auto;
  outline: none;
}

.date {
  &::v-deep .vdatetime-input {
    padding: 12px 44px 12px 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d6d6d6;
    font-size: 16px;
    cursor: pointer;
    // pr-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg text-base
  }
}

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 4px;
    margin-right: 10px;
  }

  &:hover + label:before {
    background: #3b82f6;
    opacity: 0.3;
  }

  &:checked + label:before {
    background: #3b82f6;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
